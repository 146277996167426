import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import hljs from 'highlight.js';
// XML is the correct library for HTML
import html from 'highlight.js/lib/languages/xml';
import javascript from 'highlight.js/lib/languages/javascript';
import FooterCallToAction from '../FooterCallToAction';
import Article from '../../models/Article';
import formatDateString from '../../utils/formatDateString';
import BlogNavbar from '../BlogNavbar';
import Seo from '../Seo';
import authorImage from './assets/nate.jpg';
import 'highlight.js/styles/mono-blue.css';
import '../../index.scss';
import './styles.scss';

hljs.registerLanguage('html', html);
hljs.registerLanguage('javascript', javascript);
hljs.configure({ languages: ['html', 'javascript'] });

export default class ArticlePage extends Component<{ pageContext: { article: Article } }> {

  componentDidMount() {

    hljs.initHighlightingOnLoad();
    this._highlightCode();
  }

  render() {
    return (
      <div className="article-page" ref={this._saveRef}>
        <Seo title={this._article.title} description={this._article.description} image={this._article.linkPreviewImage}/>
        <div className="article-top-section">
          <BlogNavbar/>
          <h1>{this._article.title}</h1>
          <div className="article-info-container">
            <div className="article-info">
              <img src={authorImage} alt="Nate Hill"/>
              <div>
                <address>Nate Hill</address>
                <time dateTime={this._article.date}>{formatDateString(this._article.date, true)}</time>
              </div>
            </div>
          </div>
        </div>
        <article>
          <ReactMarkdown source={this._article.content.trim()} escapeHtml={false}/>
        </article>
        <FooterCallToAction/>
      </div>
    );
  }

  private _componentElement: any;

  private get _article(): Article {
    return this.props.pageContext.article;
  }

  private _highlightCode() {

    const nodes = this._componentElement.querySelectorAll('pre code');
    for (let i = 0; i < nodes.length; i++) {
      hljs.highlightBlock(nodes[i])
    }
  }

  private _saveRef = (componentElement) => this._componentElement = componentElement;
}
