import React, { Component } from 'react';
import Helmet from 'react-helmet';

const TITLE_TEMPLATE = `%s | 3D WebView for Unity`
const DEFAULT_DESCRIPTION = `Easily add an interactive web browser to your Unity 3D scenes to display websites and HTML. Works with Android, iOS, Windows, macOS, Oculus Quest, Universal Windows Platform (UWP), and Hololens.`;
const AUTHOR = `@vuplexbrowser`;


interface SeoProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: any[];
  keywords?: string[];
  image?: string;
}

export default class Seo extends Component<SeoProps> {

  render() {

    const metaDescription = this.props.description || DEFAULT_DESCRIPTION;
    const { title, keywords } = this.props;

    const metaTags = [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: AUTHOR,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ];

    if (keywords && keywords.length) {
      metaTags.push({
        name: `keywords`,
        content: keywords.join(`, `),
      });
    }

    if (this.props.image) {
      // Image URLs must be absolute
      let { image } = this.props;
      if (image.indexOf('http') !== 0) {
        image = 'https://blog.vuplex.com' + image;
      }
      metaTags.push({
          property: `og:image`,
          content: image,
      });
      metaTags.push({
        name: `twitter:image`,
        content: image,
      });
    }

    if (this.props.meta) {
      metaTags.push(...this.props.meta);
    }

    return (
      <Helmet
        htmlAttributes={{
          lang: this.props.lang || 'en',
        }}
        title={title}
        titleTemplate={TITLE_TEMPLATE}
        meta={metaTags}
      />
    )
  }
}
