
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export default function formatDateString(dateString, abbreviateMonth=false) {

  const [year, monthDigits, date] = dateString.split('-');
  let month = months[parseInt(monthDigits) - 1];
  if (abbreviateMonth) {
    month = month.slice(0, 3);
  }
  return `${month} ${parseInt(date)}, ${year}`;
}
