import React, { Component } from 'react';
import FooterCallToAction from '../FooterCallToAction';
import Article from '../../models/Article';
import ArticlePreview from '../ArticlePreview';
import BlogNavbar from '../BlogNavbar';
import Seo from '../Seo';
import './styles.scss';

export default class ArticleListPage extends Component<{ pageContext: { articles: Article[] } }> {

  render() {
    return (
      <div className="article-list-page">
        <Seo title="Articles"/>
        <BlogNavbar/>
        <div className="page-top-section">
          <h1>Articles</h1>
          <a href="/">view all news</a>
        </div>
        <div className="article-list">
          {this.props.pageContext.articles.map(article => <ArticlePreview article={article}/>)}
        </div>
        <FooterCallToAction/>
      </div>
    );
  }
}
