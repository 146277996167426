import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { OutboundLink } from 'react-ga';
import NewsPost, { NewsPostImage } from '../../../models/NewsPost';
import formatDateString from '../../../utils/formatDateString';
import './styles.scss';

export default class NewsPostView extends Component<{ post: NewsPost }> {

  render() {

    const { post } = this.props;

    return (
      <div className="news-post" key={post.title}>
        <div>
          <h2>{post.title}</h2>
          <time dateTime={post.date}>{formatDateString(post.date)}</time>
          {post.content && <ReactMarkdown className={post.hideListBullets ? 'hide-bullets' : ''} source={post.content.trim()}/>}
          {this._renderVideo()}
          {this._renderImage()}
          {this._renderSocialLinks()}
        </div>
      </div>
    )
  }

  private _getDomain(url: string) {

    const withoutScheme = url.split('://')[1];
    const fullDomain = withoutScheme.split('/')[0];
    const topLevelDomain = fullDomain.split('.').slice(-2).join('.');
    return topLevelDomain;
  }

  private _renderFacebookLink() {

    if (this.props.post.facebookUrl) {
      return (
        <OutboundLink to={this.props.post.facebookUrl} eventLabel="Facebook post">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.657 167.657" width="512" height="512"><path d="M83.829.349C37.532.349 0 37.881 0 84.178c0 41.523 30.222 75.911 69.848 82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037 12.238-30.956 30.115-30.956 8.562 0 15.92.638 18.056.919v20.944l-12.399.006c-9.72 0-11.594 4.618-11.594 11.397v14.947h23.193l-3.025 23.42H94.026v65.653c41.476-5.048 73.631-40.312 73.631-83.154 0-46.273-37.532-83.805-83.828-83.805z" fill="#cccccc"/></svg>
        </OutboundLink>
      );
    }
  }

  private _renderImage() {

    const { images } = this.props.post;
    if (!(images && images.length)) {
      return;
    }

    // For now, just a single images is supported.
    const [ image ] = images;

    if (image.linkUrl) {
      return (
        <OutboundLink className="post-image" to={image.linkUrl} eventLabel="Post image link">
          <img src={image.url} alt={image.title || image.alt} title={image.title}/>
          {this._renderImageInfo(image)}
        </OutboundLink>
      );
    }

    return (
      <div className="post-image">
        <img src={image.url} alt={image.title} title={image.title} />
        {this._renderImageInfo(image)}
      </div>
    );
  }

  private _renderImageInfo(image: NewsPostImage) {

    const { title, description, linkUrl } = image;
    if (!(title || description || linkUrl)) {
      return;
    }
    const domain = linkUrl ? this._getDomain(linkUrl) : null;

    return (
      <div className="image-info">
        {title && <h3>{title}</h3>}
        {description && <p>{description}</p>}
        {domain && <div>{domain}</div>}
      </div>
    );
  }

  private _renderLinkedInLink() {

    if (this.props.post.linkedInUrl) {
      return (
        <OutboundLink to={this.props.post.linkedInUrl} eventLabel="LinkedIn post">
          <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm-74.390625 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0" fill="#cccccc"/></svg>
        </OutboundLink>
      );
    }
  }

  private _renderSocialLinks() {

    const { post } = this.props;
    if (post.twitterUrl || post.facebookUrl) {
      return (
        <div className="social-links">
          {this._renderTwitterLink()}
          {this._renderFacebookLink()}
          {this._renderLinkedInLink()}
        </div>
      )
    }
  }

  private _renderTwitterLink() {

    if (this.props.post.twitterUrl) {
      return (
        <OutboundLink to={this.props.post.twitterUrl} eventLabel="Twitter post">
          <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 49.652 49.652"><path d="M24.826 0C11.137 0 0 11.137 0 24.826c0 13.688 11.137 24.826 24.826 24.826 13.688 0 24.826-11.138 24.826-24.826C49.652 11.137 38.516 0 24.826 0zm11.075 19.144c.011.246.017.494.017.742 0 7.551-5.746 16.255-16.259 16.255-3.227 0-6.231-.943-8.759-2.565.447.053.902.08 1.363.08 2.678 0 5.141-.914 7.097-2.446a5.72 5.72 0 0 1-5.338-3.969 5.76 5.76 0 0 0 2.58-.096 5.715 5.715 0 0 1-4.583-5.603l.001-.072a5.69 5.69 0 0 0 2.587.714 5.71 5.71 0 0 1-2.541-4.755c0-1.048.281-2.03.773-2.874a16.225 16.225 0 0 0 11.777 5.972 5.707 5.707 0 0 1-.147-1.303 5.714 5.714 0 0 1 9.884-3.91 11.406 11.406 0 0 0 3.63-1.387 5.74 5.74 0 0 1-2.516 3.162 11.36 11.36 0 0 0 3.282-.899 11.494 11.494 0 0 1-2.848 2.954z" fill="#cccccc"/></svg>
        </OutboundLink>
      );
    }
  }

  private _renderVideo() {

    if (!this.props.post.videoUrl) {
      return;
    }
    return (
      <video muted autoPlay loop playsinline src={this.props.post.videoUrl}/>
    );
  }
}
