import React, { Component } from 'react';
import assetStoreImage from '../../shared-assets/asset-store-badge.png';
import './styles.scss';

export default class FooterCallToAction extends Component {

  render() {
    return (
      <div className="footer-call-to-action">
        <a href="https://developer.vuplex.com">
          <p>
            Buy 3D WebView on the Asset Store:
          </p>
          <div className="badge">
            <img src={assetStoreImage} alt="Vuplex VR Web Browser is available on the Google Play Store" />
          </div>
        </a>
      </div>
    );
  }
}
