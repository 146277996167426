import React from 'react';
import Navbar from '../web-common/components/Navbar';

const BlogNavbar = () => (
  <Navbar
    siteName="blog"
    centerLinks={[
      <a key="store" href="https://store.vuplex.com">store</a>,
      <a key="developers" href="https://developer.vuplex.com">developers</a>,
      <a key="support" href="https://support.vuplex.com">support</a>,
    ]}/>
);

export default BlogNavbar;
