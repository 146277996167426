// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-article-list-page-index-tsx": () => import("/Users/nate/repos/vx/vuplex-web/blog.vuplex.com/src/components/ArticleListPage/index.tsx" /* webpackChunkName: "component---src-components-article-list-page-index-tsx" */),
  "component---src-components-news-feed-page-index-tsx": () => import("/Users/nate/repos/vx/vuplex-web/blog.vuplex.com/src/components/NewsFeedPage/index.tsx" /* webpackChunkName: "component---src-components-news-feed-page-index-tsx" */),
  "component---src-components-article-page-index-tsx": () => import("/Users/nate/repos/vx/vuplex-web/blog.vuplex.com/src/components/ArticlePage/index.tsx" /* webpackChunkName: "component---src-components-article-page-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/nate/repos/vx/vuplex-web/blog.vuplex.com/.cache/data.json")

