import React, { Component } from 'react';
import FooterCallToAction from '../FooterCallToAction';
import Seo from '../Seo';
import NewsPost from '../../models/NewsPost';
import Article from '../../models/Article';
import ArticlePreview from '../ArticlePreview';
import BlogNavbar from '../BlogNavbar';
import NewsPostView from './NewsPostView';
import './styles.scss';

export default class NewsFeedPage extends Component<{ pageContext: { items: Array<NewsPost|Article> } }> {

  render() {
    return (
      <div className="news-feed">
        <Seo title="Blog"/>
        <BlogNavbar/>
        <div className="page-top-section">
          <h1>Latest news</h1>
          <a href="/articles">view articles</a>
        </div>
        <div className="post-list">
          {this.props.pageContext.items.map(this._renderItem)}
        </div>
        <FooterCallToAction/>
      </div>
    );
  }

  private _renderItem = (item: NewsPost|Article) => {

    if ('urlPath' in item) {
      return <ArticlePreview article={item} includeBorder={true}/>
    }
    return <NewsPostView post={item}/>
  }
}
