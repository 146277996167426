import React, { Component } from 'react';
import formatDateString from '../../utils/formatDateString';
import Article from '../../models/Article';
import './styles.scss';

export default class ArticlePreview extends Component<{ article: Article, includeBorder?: boolean }> {

  render() {

    const { article } = this.props;
    return (
      <a href={article.urlPath}
         className={`article-preview ${this.props.includeBorder ? 'with-border' : ''}`}
         key={article.title}>
        {this._renderBanner()}
        <div className="content">
          <div>
            <img src={article.thumbnail} alt="" />
          </div>
          <div>
            <h2>{article.title}</h2>
            <time dateTime={article.date}>{formatDateString(article.date)}</time>
            <p>{article.description || this._getPreview()}</p>
          </div>
        </div>
      </a>
    )
  }

  private _getPreview() {

    const words = this.props.article.content.replace(/(<([^>]+)>)/ig, '') // remove markup
      .slice(0, 150)
      .split(' ');
    words.pop();
    return words.join(' ') + '…';
  }

  private _renderBanner() {
    if (this.props.includeBorder) {
      return (
        <div className="banner">
          <div>
            <span>Article</span>
          </div>
        </div>
      );
    }
  }
}
